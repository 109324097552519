import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import ListItem from '@components/pages/side-kick-session/sections/content/components/feed/list-item';
import { useTranslation } from '@desygner/ui-common-translation';
import useScrollIntoView from '@hooks/useScrollIntoView';
import { Typography } from '@mui/material';
import MuiList from '@mui/material/List';
import { useRef } from 'react';

//TODO: for now we only use FactChecker later on we extend this to other agents
export default function List() {
  const { dialogues, remoteParticipants } = useSideKick();

  const { t } = useTranslation();

  const listRef = useRef(null);

  const doWeHaveParticipants = remoteParticipants.length > 0;

  useScrollIntoView(listRef, [dialogues.length]);

  return (
    <MuiList disablePadding ref={listRef}>
      {doWeHaveParticipants ? (
        Object.entries(dialogues).map(
          ([id, dialogue]) =>
            dialogue.factCheck && (
              <ListItem
                key={id}
                time={dialogue.time}
                agent="factChecker"
                result={dialogue.factCheck}
              />
            ),
        )
      ) : (
        <Typography>
          {t('page.sideKickSession.waitingForParticipants', {
            defaultValue: 'Connecting to the room...',
          })}
        </Typography>
      )}
    </MuiList>
  );
}
