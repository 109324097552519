const DRAWER = {
  WIDTH: 400,
};

const PLAYER_HEIGHT = {
  MOBILE: 200,
  DESKTOP: 30,
};

const CONFIG = {
  DRAWER,
  PLAYER_HEIGHT,
} as const;

export default CONFIG;
