import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  height: '100%',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(6),
}));

export default StyledStack;
