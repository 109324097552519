import { useState } from 'react';
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
} from '@components/pages/knowledge-base/components/table/action-drawer/styles';
import Feed from '@components/pages/side-kick-session/sections/content/components/feed';
import Transcript from '@components/pages/side-kick-session/sections/content/components/transcript';
import TabContext from '@mui/lab/TabContext';
import Recorder from '@components/pages/side-kick-session/recorder/mobile';
import CONFIG from '@components/pages/side-kick-session/config';
import { convertToRem } from '@lib/fonts';

type TabValueType = 'Transcript' | 'Feed';

export default function Content() {
  const [value, setValue] = useState<TabValueType>('Transcript');

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: TabValueType,
  ) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <StyledTabPanel
        value="Transcript"
        sx={{
          m: 0,
          height: `calc(100% - ${convertToRem(
            CONFIG.PLAYER_HEIGHT.MOBILE + 20,
          )})`,
        }}
      >
        <Transcript />
      </StyledTabPanel>
      <StyledTabPanel
        value="Feed"
        sx={{
          m: 0,
          height: `calc(100% - ${convertToRem(
            CONFIG.PLAYER_HEIGHT.MOBILE + 20,
          )})`,
        }}
      >
        <Feed />
      </StyledTabPanel>

      <Recorder>
        <StyledTabList
          variant="fullWidth"
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              bottom: '50%',
            },
            sx: {
              height: 'calc(100% - 5px)',
              transform: 'translateY(50%)',
              backgroundColor: 'background.card.light',
              borderRadius: 2,
            },
          }}
        >
          <StyledTab label={'Transcript'} value="Transcript" />
          <StyledTab label={'Feed'} value="Feed" />
        </StyledTabList>
      </Recorder>
    </TabContext>
  );
}
