import useCreateNewStyledAssistant from '@components/pages/assistants/hooks/useCreateNewStyledAssistant';
import CONFIG from '@components/pages/assistants/sections/drawer/add-assistants/config';
import { StyledStack } from '@components/pages/assistants/sections/drawer/add-assistants/styles';
import { SubmitDataType } from '@components/pages/assistants/sections/drawer/add-assistants/types';
import Heading from '@components/pages/assistants/sections/drawer/heading';
import addAssistantsSchema from '@components/pages/assistants/sections/drawer/schema';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFCheckbox from '@components/react-hook-form/RHFCheckbox';
import RHFSelect from '@components/react-hook-form/RHFSelect';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';

const DEFAULT_VALUES = {
  name: '',
  description: '',
  supportsPublicFallback: true,
  supportsPublicDefault: false,
  voice: CONFIG.COMMUNICATION_STYLES[0].suggestions[0],
  personality: CONFIG.COMMUNICATION_STYLES[1].suggestions[0],
  response_style: CONFIG.COMMUNICATION_STYLES[2].suggestions[0],
};

export default function AddAssistantsDrawer() {
  const { t } = useTranslation();
  const {
    mutateAsync: mutateCreateNewAssistantsAsync,
    isPending: isCreateNewAssistantsPending,
  } = useCreateNewStyledAssistant();
  const methods = useForm({
    resolver: yupResolver(addAssistantsSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = methods;

  const isBusy = isCreateNewAssistantsPending || !isDirty;

  async function onSubmit(data: SubmitDataType) {
    //TODO: Implement API call to create assistant
    await mutateCreateNewAssistantsAsync(data);
  }

  function handleSupportPublicFallback(e: React.FormEvent<HTMLLabelElement>) {
    const target = e.target as HTMLInputElement;
    setValue('supportsPublicFallback', target.checked);
    if (target.checked) {
      setValue('supportsPublicDefault', false);
    }
  }

  function handleSupportPublicDefault(e: React.FormEvent<HTMLLabelElement>) {
    const target = e.target as HTMLInputElement;
    setValue('supportsPublicDefault', target.checked);
    if (target.checked) {
      setValue('supportsPublicFallback', false);
    }
  }

  return (
    <>
      <Heading
        title={t('page.assistants.drawer.addAssistants.title', {
          defaultValue: 'Create an assistants',
        })}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <StyledStack gap={4}>
          <Stack component="label" gap={2}>
            <Typography variant="body2">
              {t('page.page.assistants.drawer.addAssistants.form.name.label', {
                defaultValue: 'Name',
              })}
            </Typography>
            <RHFTextField
              name="name"
              size="small"
              placeholder={t(
                'page.page.assistants.drawer.addAssistants.form.name.placeholder',
                {
                  defaultValue: 'Assistant Name',
                },
              )}
            />
          </Stack>

          <Stack component="label" gap={2}>
            <Typography variant="body2">
              {t(
                'page.assistants.drawer.addAssistants.form.description.label',
                {
                  defaultValue: 'Description',
                },
              )}
            </Typography>
            <RHFTextField
              name="description"
              size="small"
              placeholder={t(
                'page.assistants.drawer.addAssistants.form.description.placeholder.label',
                {
                  defaultValue: 'Assistant Description',
                },
              )}
              multiline
              rows={4}
            />
          </Stack>
          <RHFCheckbox
            onInput={handleSupportPublicFallback}
            name="supportsPublicFallback"
            label={t(
              'page.assistants.drawer.addAssistants.form.fallbackSupport.label',
              {
                defaultValue:
                  'Automatically fallback to public knowledge when the answer can not be found in the provided knowledge.',
              },
            )}
          />
          <RHFCheckbox
            onInput={handleSupportPublicDefault}
            name="supportsPublicDefault"
            label={t(
              'page.assistants.drawer.addAssistants.form.publicDefault.label',
              {
                defaultValue:
                  'Allow the user to enquire public knowledge when the answer can not be found in the provided knowledge.',
              },
            )}
          />
          <Accordion
            disableGutters
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
              <Typography variant="body2">
                {t(
                  'page.assistants.drawer.addAssistants.communicationStyles.title',
                  {
                    defaultValue: 'Communication style',
                  },
                )}
                <br />
                <Box component="span" sx={{ mt: 2, color: 'text.disabled' }}>
                  {t(
                    'page.assistants.drawer.addAssistants.communicationStyles.description',
                    {
                      defaultValue:
                        "Personalis your AI's communication style and response structure to align perfectly with your preferences and needs.",
                    },
                  )}
                </Box>
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack sx={{ mt: 5 }} gap={5}>
                {CONFIG.COMMUNICATION_STYLES.map((style) => {
                  return (
                    <Stack gap={2} key={style.label}>
                      <Typography variant="body2">{style.label}</Typography>
                      <RHFSelect name={style.name} fullWidth size="small">
                        <optgroup
                          defaultValue={style.suggestions[0]}
                          key={style.label}
                          label={style.label}
                        >
                          {style.suggestions.map((suggestion) => {
                            return (
                              <option value={suggestion} key={suggestion}>
                                {suggestion}
                              </option>
                            );
                          })}
                        </optgroup>
                      </RHFSelect>
                    </Stack>
                  );
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </StyledStack>
      </FormProvider>
      <Box sx={{ marginTop: 'auto' }}>
        <Divider />
        <Stack sx={{ p: 4 }}>
          <LoadingButton
            fullWidth
            variant="contained"
            disabled={isBusy}
            loading={isCreateNewAssistantsPending}
            onClick={handleSubmit(onSubmit)}
          >
            {t('page.assistants.drawer.addAssistants.form.submitButton', {
              defaultValue: 'Add Assistant',
            })}
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
}
