import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { StyledStack } from '@components/pages/side-kick/sections/cards/smart-tags-card/components/tag-list-item/styles';
type Props = {
  title: {
    text: string;
    variant: TypographyProps['variant'];
  };
  subtitle?: string;
  checkbox: {
    position?: 'start' | 'end';
    Component: React.ReactNode;
  };
};

export default function TagListItem({ subtitle, title, checkbox }: Props) {
  const { Component: CheckboxComponent } = checkbox;
  const isCheckboxEnd = checkbox.position === 'end';

  return (
    <StyledStack direction="row" gap={10}>
      <Stack direction="row" gap={3}>
        {!isCheckboxEnd && CheckboxComponent}
        <Stack>
          <Typography variant={title.variant}>{title.text}</Typography>
          {subtitle && (
            <Typography variant="body2" color="text.disabled">
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
      {isCheckboxEnd && CheckboxComponent}
    </StyledStack>
  );
}
