import Timer from '@components/pages/side-kick-session/recorder/LiveTimer';
import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import PauseIcon from '@mui/icons-material/Pause';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

const SHARED_PROPS = {
  disableRipple: true,
  disableTouchRipple: true,
};

export default function Recorder({ children }: React.PropsWithChildren) {
  return (
    <Box
      sx={{
        backgroundColor: 'background.card.light',
        position: 'sticky',
        bottom: CONFIG_TOOLBAR.TOOLBAR_MINI.MOBILE.HEIGHT,
        left: 0,
        right: 0,
        borderRadius: 0,
        p: 4.5,
      }}
    >
      <Stack gap={4}>
        {children}
        <Timer />
        <Stack
          direction="row"
          gap={2.5}
          sx={{
            justifyContent: 'center',
          }}
        >
          <ButtonBase {...SHARED_PROPS}>
            <PauseIcon fontSize="large" />
          </ButtonBase>
          <ButtonBase {...SHARED_PROPS}>
            <RadioButtonCheckedIcon color="error" fontSize="large" />
          </ButtonBase>
          <ButtonBase {...SHARED_PROPS}>
            <TuneIcon fontSize="large" />
          </ButtonBase>
        </Stack>
      </Stack>
    </Box>
  );
}
