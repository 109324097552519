import UncontrolledAccordion from '@components/pages/side-kick-session/sections/content/components/feed/list-item/uncontrolled-accordion';
import Details from '@components/pages/side-kick-session/sections/content/components/feed/list-item/uncontrolled-accordion/details';
import Summary from '@components/pages/side-kick-session/sections/content/components/feed/list-item/uncontrolled-accordion/summary';
import MuiListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AgentsType, FactCheckType } from '@shared-types/sidekick';
import Paper from '@mui/material/Paper';

type Props = {
  time: {
    seconds: string;
    minutes: string;
  };
  agent: AgentsType;
  result: Array<{
    outcome: FactCheckType['result'][number]['outcome']; //TODO: we need to extend this to other agents
    body: string;
  }>;
};

//TODO: for now we only use FactCheck later on we extend this to other agents
export default function ListItem({ agent, time, result }: Props) {
  return (
    <MuiListItem disablePadding disableGutters sx={{ width: '100%' }}>
      <Stack sx={{ flex: 1, borderRadius: 2 }} gap={2}>
        <Typography variant="body2" component="time" color="text.secondary">
          {time.minutes}:{time.seconds}
        </Typography>
        <Paper sx={{ border: '1px solid', borderColor: 'divider' }}>
          {result.map((res, index) => (
            <UncontrolledAccordion
              key={index}
              SummarySlot={<Summary outcome={res.outcome} agent={agent} />}
              DetailsSlot={<Details reasoning={res.body} />}
            />
          ))}
        </Paper>
      </Stack>
    </MuiListItem>
  );
}
