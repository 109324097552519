import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import useAppSelector from '@hooks/useAppSelector';
import { useTranslation } from '@desygner/ui-common-translation';
import useAppDispatch from '@hooks/useAppDispatch';
import {
  AssistantsKindType,
  SelectedAssistantsType,
} from '@shared-types/transcription';
import { selectedAssistantsUpdated } from '@state/slices/transcription';
import CONFIG from '@components/pages/side-kick-session/sections/content/components/feed/selector/config';
import { StyledCheckbox, StyledCheckboxIcon } from '@components/pages/styles';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';

const MenuProps: SelectProps['MenuProps'] = {
  MenuListProps: {
    disablePadding: true,
  },
  PaperProps: {
    sx: {
      p: 0,
      backgroundColor: 'background.card.light',
    },
    style: {
      minWidth: 250,
    },
  },
};

export default function Selector() {
  const { t } = useTranslation();

  const selectedAssistants = useAppSelector(
    (state) => state.transcription.selectedAssistants,
  );

  const dispatch = useAppDispatch();

  const handleChange = (event: SelectChangeEvent<AssistantsKindType[]>) => {
    const modes = {} as SelectedAssistantsType;
    const value = event.target.value;
    if (!Array.isArray(value)) return null;

    value.forEach((mode) => {
      modes[mode] = !modes[mode];
    });

    dispatch(
      selectedAssistantsUpdated({
        ...modes,
      }),
    );
  };

  const value = useMemo(
    () =>
      Object.entries(selectedAssistants)
        .filter(Boolean)
        .map(([key, _value]) => key) as AssistantsKindType[],
    [selectedAssistants],
  );

  return (
    <>
      <Typography>
        {t('page.sideKickSession.feed.title', {
          defaultValue: 'Feed',
        })}
      </Typography>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={value}
        size="small"
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          sx: { backgroundColor: 'background.default' },
        }}
        renderValue={(selected) => (
          <Box>
            {selected.length === CONFIG.ASSISTANTS.length ? (
              <Typography variant="body2">General</Typography>
            ) : selected.length > 0 &&
              selected.length < CONFIG.ASSISTANTS.length ? (
              <Typography variant="body2">Custom</Typography>
            ) : (
              <Typography variant="body2">No Modes</Typography>
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {CONFIG.ASSISTANTS.map(({ fieldName, label, image }) => (
          <MenuItem
            disableRipple
            disableTouchRipple
            disableGutters
            key={label}
            value={fieldName}
          >
            <Checkbox
              icon={<StyledCheckboxIcon />}
              checkedIcon={<StyledCheckbox />}
              checked={fieldName in selectedAssistants}
            />
            <Stack direction="row" gap={2.5}>
              <img src={image} alt="sad" width={24} height={24} />
              {label}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
