import Markdown from '@components/markdown';
import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';

type Props = {
  reasoning: string;
};

export default function Details({ reasoning }: Props) {
  return (
    <AccordionDetails sx={{ p: 0 }}>
      <Stack gap={2}>
        <Markdown>{reasoning}</Markdown>
      </Stack>
    </AccordionDetails>
  );
}
