import useLiveKitTranscriber from '@components/pages/side-kick-session/hooks/useTranscriber';
import ListItem from '@components/pages/side-kick-session/sections/content/listitem';
import useScrollIntoView from '@hooks/useScrollIntoView';
import useTimer from '@hooks/useTimer';
import { useRoomContext } from '@livekit/components-react';
import { useRef } from 'react';

export default function LiveKitAudioTranscriber() {
  const { minutes, seconds } = useTimer();
  const room = useRoomContext();

  const { transcript } = useLiveKitTranscriber(room);

  const chatRef = useRef(null);

  useScrollIntoView(chatRef, [transcript]);

  return (
    <ListItem
      ref={chatRef}
      time={{
        minutes: minutes.toString(),
        seconds: seconds.toString(),
      }}
      content={transcript}
    />
  );
}
