import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';
import CONFIG from '@components/pages/side-kick/config';
import TagListItem from '@components/pages/side-kick/sections/cards/smart-tags-card/components/tag-list-item';
import { SmartTagsKindType } from '@components/pages/side-kick/types';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { StyledStack } from '@components/pages/side-kick/sections/cards/smart-tags-card/kinds/list/list-item/styles';
import { selectedSmartTagsUpdated } from '@state/slices/transcription';
import { Fragment } from 'react';

type Props = {
  category: SmartTagsKindType;
  items: {
    title: string;
    subtitle: string;
    id: number;
    uuid: string;
  }[];
  title: string;
};

export default function ListItem({ category, items, title }: Props) {
  const smartTags = useAppSelector((state) => state.transcription.smartTags);

  const dispatch = useAppDispatch();

  function handleCategoryToggle({
    category,
    checked,
  }: {
    category: SmartTagsKindType;
    checked: boolean;
  }) {
    const ids = CONFIG.SMART_TAGS[category].items.map((item) => item.id);
    dispatch(
      selectedSmartTagsUpdated({
        ...smartTags,
        [category]: checked ? ids : [],
      }),
    );
  }

  function handleItemToggle({
    category,
    checked,
    itemId,
  }: {
    category: SmartTagsKindType;
    itemId: number;
    checked: boolean;
  }) {
    dispatch(
      selectedSmartTagsUpdated({
        ...smartTags,
        [category]: checked
          ? [...smartTags[category], itemId]
          : smartTags[category].filter((id) => id !== itemId),
      }),
    );
  }

  const allChecked = smartTags[category]?.length === items.length;
  const someChecked = smartTags[category]?.length > 0 && !allChecked;

  return (
    <StyledStack>
      <TagListItem
        checkbox={{
          Component: (
            <Checkbox
              onChange={(_event, checked) =>
                handleCategoryToggle({ category, checked })
              }
              checked={allChecked}
              indeterminate={someChecked}
              icon={<StyledCheckboxIcon />}
              checkedIcon={<StyledCheckbox />}
              indeterminateIcon={<StyledIntermittentCheckbox />}
            />
          ),
          position: 'end',
        }}
        title={{
          text: title,
          variant: 'subtitle1',
        }}
      />
      <Divider />
      {items.map(({ id, title, subtitle, uuid }, index, originalItems) => {
        return (
          <Fragment key={uuid}>
            <TagListItem
              checkbox={{
                Component: (
                  <Checkbox
                    onChange={(_event, checked) =>
                      handleItemToggle({ category, itemId: id, checked })
                    }
                    checked={smartTags[category]?.includes(id)}
                    icon={<StyledCheckboxIcon />}
                    checkedIcon={<StyledCheckbox />}
                    indeterminateIcon={<StyledIntermittentCheckbox />}
                  />
                ),
                position: 'end',
              }}
              title={{
                text: title,
                variant: 'body1',
              }}
              subtitle={subtitle}
            />
            {index !== originalItems.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </StyledStack>
  );
}
