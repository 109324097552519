//TODO: for now I just hard coded speaker later we change it to dynamic 🚀
import useGetSentimentLabelsFromCDN from '@components/pages/side-kick-session/hooks/useGetSentimentLabelsFromCDN';
import calculateFactCheckColor from '@components/pages/side-kick-session/utils/calculateFactCheckColor';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import MuiListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MonologueType } from '@shared-types/sidekick';
import { forwardRef, memo } from 'react';

const ListItem = forwardRef<HTMLLIElement, MonologueType>(
  ({ content, time, sentiments, factCheck }, ref) => {
    const { data: sentimentLabelsFromCDN } = useGetSentimentLabelsFromCDN();

    if (!content) return null;

    return (
      <MuiListItem disableGutters ref={ref}>
        <Stack gap={3} sx={{ width: '100%', alignItems: 'flex-start' }}>
          <Stack
            direction="row"
            gap={3}
            sx={{
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Avatar alt="John Doe" sx={{ width: 24, height: 24 }} />
            <Typography variant="subtitle2">Speaker</Typography>
            <Typography variant="body2" color="text.secondary">
              {time.minutes.padStart(2, '0')}:{time.seconds.padEnd(2, '0')}
            </Typography>
          </Stack>
          <Typography sx={{ ml: 9 }} variant="body2">
            {content}
          </Typography>
          <Stack
            direction="row"
            gap={2}
            sx={{
              flexWrap: 'wrap',
            }}
          >
            {sentiments?.map(({ sentiment, classification }, index) => (
              <Chip
                key={index}
                label={sentiment}
                size="small"
                variant="outlined"
                color={
                  sentimentLabelsFromCDN?.[classification]?.[sentiment] ??
                  'default'
                }
                sx={{
                  borderRadius: 1.5,
                }}
              />
            ))}
            {factCheck?.map((factCheck, index) => (
              <Chip
                key={index}
                label={factCheck.outcome}
                size="small"
                variant="outlined"
                color={calculateFactCheckColor(factCheck.outcome)}
                sx={{
                  borderRadius: 1.5,
                }}
              />
            ))}
          </Stack>
        </Stack>
      </MuiListItem>
    );
  },
);

ListItem.displayName = 'ListItem';

export default memo(ListItem);
