import { ThemeType } from '@shared-types/theme';

export default function Accordion(theme: ThemeType) {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          alignItems: 'start',
          '&.Mui-expanded': {
            minHeight: 0,
          },
          minHeight: 0,
        },
        content: {
          margin: '0px!important',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
  };
}
