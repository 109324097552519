import SideKickProvider from '@components/pages/side-kick-session/providers/SideKickProvider';
import Content from '@components/pages/side-kick-session/sections/content';
import Wrapper from '@components/pages/side-kick-session/Wrapper';
import { LiveKitRoom, RoomContext } from '@livekit/components-react';
import paths from '@router/paths';
import { Room } from 'livekit-client';
import { ConfirmProvider } from 'material-ui-confirm';
import { useRef, useSyncExternalStore } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SideKickSession() {
  const { state }: { state: { token?: string } } = useLocation();

  const { current: room } = useRef<Room>(new Room());

  const navigateTo = useNavigate();

  useSyncExternalStore(
    (onStoreChange) => {
      if (state?.token == null) {
        navigateTo(paths.sideKick.pathname);
        toast.error('you need to provider token');
        onStoreChange();
      }
      return () => onStoreChange();
    },
    () => state?.token,
  );

  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: {
          variant: 'contained',
        },
        cancellationButtonProps: {
          variant: 'outlined',
        },
        allowClose: false,
      }}
    >
      <RoomContext.Provider value={room}>
        <SideKickProvider>
          <LiveKitRoom
            token={state?.token}
            serverUrl={import.meta.env.VITE_LIVE_KIT_SERVER_URL}
            room={room}
            audio={true}
            connect={true}
          >
            <Wrapper>
              <Content />
            </Wrapper>
          </LiveKitRoom>
        </SideKickProvider>
      </RoomContext.Provider>
    </ConfirmProvider>
  );
}
