/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius * 3,
  marginBlockEnd: theme.spacing(5),
}));
