import { SmartTagsKindType } from '@components/pages/side-kick/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedAssistantsType } from '@shared-types/transcription';

export type initialStateType = {
  isRecording: boolean;
  selectedAssistants: SelectedAssistantsType;
  smartTags: { [key in SmartTagsKindType]: number[] };
};

const initialState: initialStateType = {
  isRecording: false,
  selectedAssistants: {
    factChecker: true,
  },
  smartTags: {
    conversationModeration: [38, 40, 43, 49, 50, 56],
    actionPointsLogger: [37, 42, 44, 47, 48, 54, 56, 59],
    minutesTracker: [47, 52, 55, 56, 57],
    salesConversationAnalyser: [41, 39, 46, 51, 53, 54, 58, 59],
  },
};

const transcription = createSlice({
  name: 'transcription',
  initialState,
  reducers: {
    isRecordingUpdated: (state, action) => {
      return { ...state, isRecording: action.payload };
    },
    selectedAssistantsUpdated: (
      state,
      action: PayloadAction<SelectedAssistantsType>,
    ) => ({ ...state, selectedAssistants: action.payload }),
    selectedSmartTagsUpdated: (
      state,
      action: PayloadAction<initialStateType['smartTags']>,
    ) => ({
      ...state,
      smartTags: {
        ...action.payload,
      },
    }),
  },
});

export const {
  isRecordingUpdated,
  selectedSmartTagsUpdated,
  selectedAssistantsUpdated,
} = transcription.actions;

export default transcription.reducer;
