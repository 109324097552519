import { alpha } from '@mui/material/styles';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { folders, sources } from '@lib/agent';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { HashMap } from '@shared-types/utils';
import {
  forwardRef,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  TreeItem2,
  TreeItem2Label,
  TreeItem2Props,
} from '@mui/x-tree-view/TreeItem2';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2';
import Stack from '@mui/material/Stack';

type Props = {
  membershipId: string;
};

function TreeItemLabelWithLoading(props: {
  children: string;
  className: string;
  'data-is-loading': boolean;
  'data-is-disabled': boolean;
}) {
  const {
    children,
    'data-is-loading': isLoading,
    'data-is-disabled': isDisabled,
    ...other
  } = props;
  return (
    <TreeItem2Label {...other}>
      {isLoading && (
        <CircularProgress
          color="secondary"
          size="1em"
          sx={{ marginLeft: 0.5, marginTop: 1 }}
        />
      )}
      {!isLoading && (
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'top' }}
        >
          {children}
          {isDisabled ? (
            <VisibilityOffIcon />
          ) : (
            <VisibilityIcon sx={{ opacity: 0.38 }} />
          )}
        </Stack>
      )}
    </TreeItem2Label>
  );
}

const TreeItemWithLoading = forwardRef(function TreeItemWithLoadingInternal(
  props: TreeItem2Props,
  ref: React.Ref<HTMLLIElement>,
) {
  const {
    status: { disabled },
  } = useTreeItem2(props);
  return (
    <TreeItem2
      ref={ref}
      sx={{
        ...(props.children && !disabled ? {} : { pointerEvents: 'none' }),
      }}
      {...props}
      slots={{
        label: TreeItemLabelWithLoading,
      }}
      slotProps={{
        label: {
          //@ts-expect-error this works but MUI doesn't officially support this prior to v6 - see https://github.com/mui/material-ui/issues/33175#issuecomment-1469725522
          'data-is-loading': props.itemId.match(/^dummy-/),
          'data-is-disabled': disabled,
        },
      }}
    />
  );
});

export default function Folder({ membershipId }: Props) {
  const { data: allFolders, isLoading: isLoadingAllFolders } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['folders'],
      queryFn: () => folders.getAll(),
    });

  const { data: userFolders, isLoading: isLoadingUserFolders } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['folders', { as: membershipId }],
      queryFn: () => folders.getAllAs({ ownerId: membershipId }),
    });

  const [flatItems, setFlatItems] = useState<HashMap<TreeViewBaseItem>>({});
  const [treeItems, setTreeItems] = useState<TreeViewBaseItem[]>([]);
  const [disabledItemIds, setDisabledItemIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [folderLoaders, setFolderLoaders] = useState<{
    [key: string]: Promise<void>;
  }>({});

  useEffect(() => {
    if (
      typeof allFolders !== 'undefined' &&
      typeof userFolders !== 'undefined'
    ) {
      let { data: workspaceItems } = allFolders;
      let { data: userItems } = userFolders;

      if (!workspaceItems) workspaceItems = [];
      if (!userItems) userItems = [];

      const disabledItemIds: string[] = [];
      const fItems: HashMap<TreeViewBaseItem> = {};

      let dummyId = 1;
      for (const item of workspaceItems) {
        fItems[item.id] = {
          id: `${item.id}`,
          label: item.name,
          children: [
            {
              id: `dummy-${dummyId++}`,
              label: 'Loading...',
            },
          ],
        };

        userItems.find(({ id }) => id === item.id) ||
          disabledItemIds.push(`${item.id}`);
      }

      for (const item of workspaceItems) {
        if (item.parentId) {
          if (typeof fItems[item.parentId] !== 'undefined') {
            fItems[item.parentId].children!.unshift(fItems[item.id]);
          } else {
            console.error('Parent not found', item);
          }
        }
      }

      setFolderLoaders({});
      setFlatItems(fItems);
      setDisabledItemIds(disabledItemIds);
      const allItems = Object.values(fItems);
      setTreeItems(
        allItems.filter(
          (item) => !allItems.find((i) => i.children?.includes(item)),
        ),
      );
      setIsLoading(false);
    }
  }, [allFolders, isLoadingAllFolders, userFolders, isLoadingUserFolders]);

  const handleFolderExpansion = useCallback(
    (_: SyntheticEvent, item: string[]) => {
      const newLoaders: HashMap<Promise<void>> = {};

      for (const id of item) {
        if (!folderLoaders[id]) {
          newLoaders[id] = (async () => {
            const workspaceSourcesPromise = sources.getAll({
              folder: parseInt(id),
              limit: 50, // TODO: pagination
            });
            const userSourcesPromise = sources.getAllAs({
              ownerId: membershipId,
              folder: parseInt(id),
              limit: 50,
            });

            const { data: workspaceSources } =
              (await workspaceSourcesPromise) || [];
            const { data: userSources } = (await userSourcesPromise) || [];

            for (const source of workspaceSources) {
              let label = source.name;

              if (!label) {
                const fileRegex = /[^/]*$/;
                label =
                  source.path?.match(fileRegex)?.[0] || '<missing file name>';
              }

              flatItems[id].children!.push({
                id: `source-${source.id}`,
                label,
              });

              const hasAccess = userSources.find(({ id }) => id === source.id);
              hasAccess || disabledItemIds.push(`source-${source.id}`);
            }

            const dummyLoaderChildIndex = flatItems[id].children!.findIndex(
              (child) => child.id.startsWith('dummy-'),
            );
            flatItems[id].children!.splice(dummyLoaderChildIndex, 1);

            setFlatItems({ ...flatItems });
            const allItems = Object.values(flatItems);
            setTreeItems(
              allItems.filter(
                (item) => !allItems.find((i) => i.children?.includes(item)),
              ),
            );
          })();
        }
      }

      Object.keys(newLoaders).length &&
        setFolderLoaders({ ...folderLoaders, ...newLoaders });
    },
    [setFolderLoaders, folderLoaders, flatItems, disabledItemIds, membershipId],
  );

  if (isLoading) {
    return (
      <Box sx={{ m: 2 }}>
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  }

  return (
    <RichTreeView
      sx={{
        marginBottom: 1,
        '& .MuiTreeItem-content.Mui-selected': {
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: (theme) =>
              alpha(theme.palette.neutral.paper, 0.08),
          },
        },
      }}
      onExpandedItemsChange={handleFolderExpansion}
      items={treeItems}
      isItemDisabled={({ id }) => disabledItemIds.includes(id)}
      slots={{ item: TreeItemWithLoading }}
    />
  );
}
