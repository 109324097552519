import type { ThemeType } from '@shared-types/theme';
import AppBar from '@theme/overrides/AppBar';
import Button from '@theme/overrides/Button';
import Paper from '@theme/overrides/Paper';
import MenuItem from '@theme/overrides/MenuItem';
import Link from '@theme/overrides/Link';
import ListItemIcon from '@theme/overrides/ListItemIcon';
import ListItemButton from '@theme/overrides/ListItemButton';
import ListItem from '@theme/overrides/ListItem';
import Input from '@theme/overrides/Input';
import Tooltip from '@theme/overrides/Tooltip';
import Drawer from '@theme/overrides/Drawer';
import CssBaseline from '@theme/overrides/CssBaseline';
import Card from '@theme/overrides/Card';
import Skeleton from '@theme/overrides/Skeleton';
import ImageListItem from '@theme/overrides/ImageListItem';
import Toolbar from '@theme/overrides/Toolbar';
import Dialog from '@theme/overrides/Dialog';
import Autocomplete from '@theme/overrides/Autocomplete';
import Accordion from '@theme/overrides/Accordion';
import Table from '@theme/overrides/Table';
import Tab from '@theme/overrides/Tab';
import FormControlLabel from '@theme/overrides/FormControlLabel';

export default function ComponentsOverrides(theme: ThemeType) {
  return Object.assign(
    Button(theme),
    Paper(theme),
    MenuItem(theme),
    Input(theme),
    AppBar(theme),
    Link(theme),
    ListItemIcon(theme),
    ListItemButton(theme),
    ListItem(theme),
    Tooltip(theme),
    Drawer(theme),
    CssBaseline(theme),
    Card(theme),
    Skeleton(theme),
    ImageListItem(theme),
    Toolbar(theme),
    Dialog(),
    Autocomplete(),
    Accordion(theme),
    Table(theme),
    Tab(theme),
    FormControlLabel(theme),
  );
}
