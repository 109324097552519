import Category from '@components/pages/side-kick/sections/cards/smart-tags-card/kinds/category';
import List from '@components/pages/side-kick/sections/cards/smart-tags-card/kinds/list';
import { StyledCard } from '@components/pages/side-kick/styles';
import Switch from '@components/switch';
import { useTranslation } from '@desygner/ui-common-translation';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import Button from '@mui/material/ButtonBase';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export default function SmartTagsCard() {
  const { t } = useTranslation();
  const [showStatus, setShowStatus] = useState<'category' | 'list'>('category');

  return (
    <StyledCard sx={{ flex: 1 }}>
      <Stack gap={4}>
        <Stack
          direction="row"
          gap={3}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Stack direction="row" gap={3}>
            <AutoAwesomeOutlinedIcon />
            <Typography variant="subtitle1" color="text.secondary">
              {t('page.sideKick.grids.mode.cards.smartTagsCard.title', {
                defaultValue: 'Use Smart Tags',
              })}
            </Typography>
          </Stack>
          <Stack direction="row" gap={5}>
            <ButtonGroup
              variant="outlined"
              disableElevation
              disableFocusRipple
              disableRipple
            >
              <Button
                disableRipple
                disableTouchRipple
                onClick={() => setShowStatus('category')}
                sx={{
                  p: 2,
                  backgroundColor:
                    showStatus === 'category'
                      ? 'background.secondary'
                      : 'transparent',
                  borderRadius: 2,
                }}
              >
                <GridViewOutlinedIcon color="disabled" />
              </Button>
              <Button
                disableRipple
                disableTouchRipple
                onClick={() => setShowStatus('list')}
                sx={{
                  p: 2,
                  backgroundColor:
                    showStatus === 'list'
                      ? 'background.secondary'
                      : 'transparent',
                  borderRadius: 2,
                }}
              >
                <FormatListBulletedOutlinedIcon color="disabled" />
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
        <Typography variant="body1" color="text.disabled">
          {t('page.sideKick.grids.mode.cards.smartTagsCard.description', {
            defaultValue:
              'Automatically flag words and phrases based on pre-set criteria, like customer support needs, workplace compliance, NSFW content and more.',
          })}
        </Typography>

        {showStatus === 'list' ? <List /> : <Category />}
      </Stack>
    </StyledCard>
  );
}
