import CONFIG from '@components/pages/side-kick/config';
import TagListItem from '@components/pages/side-kick/sections/cards/smart-tags-card/components/tag-list-item';
import Drawer from '@components/pages/side-kick/sections/cards/smart-tags-card/kinds/category/drawer';
import { SmartTagsKindType } from '@components/pages/side-kick/types';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { selectedSmartTagsUpdated } from '@state/slices/transcription';
import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { IconButton } from '@mui/material';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';

const ARRAY_OF_SMART_TAGS = Object.entries(CONFIG.SMART_TAGS) as [
  SmartTagsKindType,
  {
    title: string;
    items: {
      title: string;
      subtitle: string;
      id: number;
      uuid: string;
    }[];
  },
][];

export default function Category() {
  const [openDrawerByCategory, setOpenDrawerByCategory] =
    useState<SmartTagsKindType | null>(null);

  const smartTags = useAppSelector((state) => state.transcription.smartTags);

  const dispatch = useAppDispatch();

  function handleCloseDrawer() {
    setOpenDrawerByCategory(null);
  }

  function handleOpenDrawer(category: SmartTagsKindType) {
    setOpenDrawerByCategory(category);
  }

  function handleCategoryToggle({
    category,
    checked,
  }: {
    category: SmartTagsKindType;
    checked: boolean;
  }) {
    const ids = CONFIG.SMART_TAGS[category].items.map((item) => item.id);
    dispatch(
      selectedSmartTagsUpdated({
        ...smartTags,
        [category]: checked ? ids : [],
      }),
    );
  }

  function handleItemToggle({
    category,
    checked,
    itemId,
  }: {
    category: SmartTagsKindType;
    itemId: number;
    checked: boolean;
  }) {
    dispatch(
      selectedSmartTagsUpdated({
        ...smartTags,
        [category]: checked
          ? [...smartTags[category], itemId]
          : smartTags[category].filter((id) => id !== itemId),
      }),
    );
  }

  return (
    <Stack direction="row" gap={4} sx={{ flexWrap: 'wrap' }}>
      {ARRAY_OF_SMART_TAGS.map(
        ([category, { items: categoryItems, title: categoryTitle }]) => (
          <Drawer
            key={category}
            title={categoryTitle}
            onClick={() => handleOpenDrawer(category)}
            checkbox={
              <Checkbox
                icon={<StyledCheckboxIcon />}
                checkedIcon={<StyledCheckbox />}
                indeterminateIcon={<StyledIntermittentCheckbox />}
                checked={smartTags[category]?.length === categoryItems.length}
                indeterminate={
                  smartTags[category]?.length > 0 &&
                  smartTags[category]?.length !== categoryItems.length
                }
                onChange={(_event, checked) =>
                  handleCategoryToggle({ category, checked })
                }
              />
            }
          />
        ),
      )}
      <MuiDrawer
        open={!!openDrawerByCategory}
        onClose={handleCloseDrawer}
        anchor="right"
      >
        {openDrawerByCategory && (
          <Stack
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 3,
              marginBlockEnd: 5,
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 4,
              }}
            >
              <Typography variant="subtitle1">
                {CONFIG.SMART_TAGS[openDrawerByCategory].title}
              </Typography>
              <IconButton onClick={handleCloseDrawer}>
                <ArrowForwardOutlinedIcon />
              </IconButton>
            </Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 4,
                backgroundColor: 'grey.900',
              }}
            >
              <Typography variant="body2" color="text.disabled">
                Select All
              </Typography>
              <Checkbox
                onChange={(_event, checked) =>
                  handleCategoryToggle({
                    category: openDrawerByCategory,
                    checked,
                  })
                }
                checked={
                  CONFIG.SMART_TAGS[openDrawerByCategory].items.length ===
                  smartTags[openDrawerByCategory].length
                }
                indeterminate={
                  smartTags[openDrawerByCategory]?.length > 0 &&
                  !(
                    CONFIG.SMART_TAGS[openDrawerByCategory].items.length ===
                    smartTags[openDrawerByCategory].length
                  )
                }
                value={openDrawerByCategory}
                icon={<StyledCheckboxIcon />}
                checkedIcon={<StyledCheckbox />}
                indeterminateIcon={<StyledIntermittentCheckbox />}
              />
            </Stack>
            <Divider />
            <Box>
              {CONFIG.SMART_TAGS[openDrawerByCategory].items?.map(
                ({ title, subtitle, id, uuid }, index, originalItems) => (
                  <Fragment key={uuid}>
                    <TagListItem
                      checkbox={{
                        Component: (
                          <Checkbox
                            onChange={(_event, checked) =>
                              handleItemToggle({
                                category: openDrawerByCategory!,
                                itemId: id,
                                checked,
                              })
                            }
                            checked={smartTags[openDrawerByCategory]?.includes(
                              id,
                            )}
                            icon={<StyledCheckboxIcon />}
                            checkedIcon={<StyledCheckbox />}
                            indeterminateIcon={<StyledIntermittentCheckbox />}
                          />
                        ),
                        position: 'end',
                      }}
                      title={{
                        text: title,
                        variant: 'body1',
                      }}
                      subtitle={subtitle}
                    />
                    {index !== originalItems.length - 1 && <Divider />}
                  </Fragment>
                ),
              )}
            </Box>
          </Stack>
        )}
      </MuiDrawer>
    </Stack>
  );
}
