import CONFIG from '@components/pages/side-kick/config';
import ListItem from '@components/pages/side-kick/sections/cards/smart-tags-card/kinds/list/list-item';
import { SmartTagsKindType } from '@components/pages/side-kick/types';
import Masonry from '@mui/lab/Masonry';

const ARRAY_OF_SMART_TAGS = Object.entries(CONFIG.SMART_TAGS) as [
  SmartTagsKindType,
  {
    title: string;
    items: {
      title: string;
      subtitle: string;
      id: number;
      uuid: string;
    }[];
  },
][];

export default function List() {
  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2} defaultHeight={300}>
      {ARRAY_OF_SMART_TAGS.map(
        ([category, { items: categoryItems, title: categoryTitle }], index) => {
          return (
            <ListItem
              key={index}
              category={category}
              items={categoryItems}
              title={categoryTitle}
            />
          );
        },
      )}
    </Masonry>
  );
}
