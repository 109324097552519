//TODO: I would like to go through this with @Nax to make sure If I have covered all edge cases. so comments can be removed afterwards.
import { configureStore } from '@reduxjs/toolkit';
import AssistantResultMiddleware from '@state/middleware/assistant-result';
import SearchResultMiddleware from '@state/middleware/search-result';
import socketMiddleware from '@state/middleware/websocket';
import assistantResultSlice from '@state/slices/assistant-result';
import searchResultSlice from '@state/slices/search-result';
import transcriptionSlice from '@state/slices/transcription';
import websocket from '@state/slices/websocket';

const store = configureStore({
  reducer: {
    assistantResult: assistantResultSlice,
    searchResult: searchResultSlice,
    transcription: transcriptionSlice,
    websocket: websocket,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      socketMiddleware,
      AssistantResultMiddleware,
      SearchResultMiddleware,
    ]);
  },
});

export type RootType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
