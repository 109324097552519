import StyledStack from '@components/pages/side-kick/sections/cards/smart-tags-card/kinds/category/drawer/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  checkbox: React.ReactNode;
};

const BUTTON_SIZE = 45;

export default function Drawer({ title, onClick, checkbox }: Props) {
  return (
    <StyledStack direction="row">
      <ButtonBase
        disableRipple
        disableTouchRipple
        sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }}
        onClick={onClick}
      >
        <ExpandMoreIcon />
      </ButtonBase>
      <Divider flexItem orientation="vertical" />
      <Stack direction="row" sx={{ alignItems: 'center', p: 2 }}>
        <Typography variant="body1">{title}</Typography>
        {checkbox}
      </Stack>
    </StyledStack>
  );
}
