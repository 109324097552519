import KnowledgeBaseProvider from '@components/pages/knowledge-base/providers/KnowledgeBaseProvider';
import StyledStack from '@components/pages/knowledge-base/styles';
import { ConfirmProvider } from 'material-ui-confirm';

export default function AdminKnowledgeBase() {
  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: {
          variant: 'contained',
        },
        cancellationButtonProps: {
          variant: 'outlined',
        },
      }}
    >
      <KnowledgeBaseProvider>
        <StyledStack gap={6}>
          <KnowledgeBaseProvider.CreateFolderModal />
          <KnowledgeBaseProvider.AddKnowledgeBaseModal />
          <StyledStack sx={{ p: 0 }}>
            <KnowledgeBaseProvider.Breadcrumb />
            <KnowledgeBaseProvider.Header />
          </StyledStack>
          <KnowledgeBaseProvider.Table />
        </StyledStack>
      </KnowledgeBaseProvider>
    </ConfirmProvider>
  );
}
