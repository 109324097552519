/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import {
  LiveSideKickRequestType,
  LiveSideKickResponseType,
} from '@shared-types/sidekick';

export const live = {
  createLiveToken: (body: LiveSideKickRequestType) =>
    requests(axiosApiInstance).post<LiveSideKickResponseType>(
      '/v1/actions/live/side-kick',
      body,
    ),
};
