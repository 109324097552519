import { StyledContainerForFixedHeader } from '@components/pages/styles';
import CONFIG_HEADER from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import Stack from '@mui/material/Stack';

export default function Wrapper({ children }: React.PropsWithChildren) {
  return (
    <StyledContainerForFixedHeader
      disableGutters
      maxWidth={false}
      sx={{ pb: 0 }}
    >
      <Stack
        gap={2.5}
        sx={{
          height: `calc(100svh - ${convertToRem(
            CONFIG_HEADER.HEADER.HEIGHT + CONFIG_HEADER.HEADER.PADDING_Y * 2,
          )})`,
        }}
      >
        {children}
      </Stack>
    </StyledContainerForFixedHeader>
  );
}
