import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { AgentsType, FactCheckType } from '@shared-types/sidekick';
import calculateFactCheckColor from '@components/pages/side-kick-session/utils/calculateFactCheckColor';

const AVATAR_SIZE = 24;

type Props = {
  agent: AgentsType;
  outcome: FactCheckType['result'][number]['outcome']; //TODO: we need to extend this to other agents
};

type AvailableAgentsType = {
  [key: string]: { avatar: string; size: number; name: string };
};

//TODO: they need to be string since user can create custom agents in the future
const AGENTS: AvailableAgentsType = {
  factChecker: {
    avatar: 'https://assets.knowz.com/assets/svg/fack-checker.svg',
    size: AVATAR_SIZE,
    name: 'Fact checker',
  },
  noteTaker: {
    avatar: 'https://assets.knowz.com/assets/svg/note-taker.svg',
    size: AVATAR_SIZE,
    name: 'Note taker',
  },
  knowzScribe: {
    avatar: 'https://assets.knowz.com/assets/svg/knowz-scribe.svg',
    size: AVATAR_SIZE,
    name: 'Knowz scribe',
  },
  mentor: {
    avatar: 'https://assets.knowz.com/assets/svg/mentor.svg',
    size: AVATAR_SIZE,
    name: 'Mentor',
  },
};

export default function Summary({ outcome, agent }: Props) {
  const foundAgent = AGENTS[agent];

  return (
    <Stack
      direction="row"
      gap={2.5}
      sx={{
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
        <Avatar
          src={foundAgent.avatar}
          sx={{ width: foundAgent.size, height: foundAgent.size }}
        />
        <Typography variant="body2">{foundAgent.name}</Typography>
      </Stack>
      <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
        <Chip
          label={outcome}
          size="small"
          variant="filled"
          color={calculateFactCheckColor(outcome)}
          sx={{
            borderRadius: 1.5,
          }}
        />
      </Stack>
    </Stack>
  );
}
