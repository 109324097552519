import { useRemoteParticipants } from '@livekit/components-react';
import {
  DialoguesType,
  FactCheckType,
  MonologueType,
  SentimentLabels,
} from '@shared-types/sidekick';
import { ActionMap } from '@shared-types/utils';
import { RemoteParticipant } from 'livekit-client';
import { createContext, useCallback, useMemo, useReducer } from 'react';

type SideKickContextType = {
  dialogues: DialoguesType;
  handleSetDialogue: (args: {
    id: string;
    content: string;
    time: { seconds: string; minutes: string };
  }) => void;
  handleUpdateSentimentsById: (args: {
    id: string;
    sentiments: SentimentLabels;
  }) => void;
  handleUpdateFactCheckById: (args: {
    id: string;
    factCheck: FactCheckType;
  }) => void;
  remoteParticipants: RemoteParticipant[];
};

type SideKickType = {
  dialogues: SideKickContextType['dialogues'];
};

enum Types {
  SET_DIALOGUE = 'SET_DIALOGUE',
  UPDATE_SENTIMENTS_BY_ID = 'UPDATE_SENTIMENTS_BY_ID',
  UPDATE_FACT_CHECK_BY_ID = 'UPDATE_FACT_CHECK_BY_ID',
}

type SideKickPayload = {
  [Types.SET_DIALOGUE]: {
    id: string;
    content: string;
    time: { minutes: string; seconds: string };
  };
  [Types.UPDATE_SENTIMENTS_BY_ID]: {
    id: string;
    sentiments: MonologueType['sentiments'];
  };
  [Types.UPDATE_FACT_CHECK_BY_ID]: {
    id: string;
    factCheck: MonologueType['factCheck'];
  };
};

export type SideKickAction =
  ActionMap<SideKickPayload>[keyof ActionMap<SideKickPayload>];

export const SideKickContext = createContext<SideKickContextType | null>(null);

const initialState: SideKickType = {
  dialogues: {},
};

export default function SideKickProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const remoteParticipants = useRemoteParticipants();

  const handleSetDialogue = useCallback(
    ({
      content,
      id,
      time,
    }: {
      id: string;
      content: string;
      time: { minutes: string; seconds: string };
    }) => {
      dispatch({
        type: Types.SET_DIALOGUE,
        payload: {
          content,
          id,
          time,
        },
      });
    },
    [],
  );

  const handleUpdateSentimentsById = useCallback(
    ({ id, sentiments }: { id: string; sentiments: SentimentLabels }) => {
      dispatch({
        type: Types.UPDATE_SENTIMENTS_BY_ID,
        payload: {
          id,
          sentiments,
        },
      });
    },
    [],
  );

  const handleUpdateFactCheckById = useCallback(
    ({ factCheck, id }: { factCheck: FactCheckType; id: string }) => {
      dispatch({
        type: Types.UPDATE_FACT_CHECK_BY_ID,
        payload: {
          id,
          factCheck,
        },
      });
    },
    [],
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      remoteParticipants,
      handleSetDialogue,
      handleUpdateSentimentsById,
      handleUpdateFactCheckById,
    }),
    [
      handleUpdateFactCheckById,
      remoteParticipants,
      state,
      handleSetDialogue,
      handleUpdateSentimentsById,
    ],
  );

  return (
    <SideKickContext.Provider value={memoizedValue}>
      {children}
    </SideKickContext.Provider>
  );
}

function reducer(state: SideKickType, action: SideKickAction): SideKickType {
  switch (action.type) {
    case 'SET_DIALOGUE': {
      const { content, id, time } = action.payload;
      return {
        ...state,
        dialogues: {
          ...state.dialogues,
          [id]: {
            content,
            time,
            factCheck: null,
            sentiments: null,
          },
        },
      };
    }
    case 'UPDATE_SENTIMENTS_BY_ID': {
      const { id, sentiments } = action.payload;

      if (sentiments === null) {
        return {
          ...state,
          dialogues: {
            ...state.dialogues,
            [id]: {
              ...state.dialogues[id],
              sentiments: null,
            },
          },
        };
      }

      const sentimentsKeeper: SentimentLabels = [];

      sentiments.forEach(({ classification, sentiment }) => {
        sentimentsKeeper.push({
          classification,
          sentiment,
        });
      });

      return {
        ...state,
        dialogues: {
          ...state.dialogues,
          [id]: {
            ...state.dialogues[id],
            sentiments: sentimentsKeeper,
          },
        },
      };
    }
    case 'UPDATE_FACT_CHECK_BY_ID': {
      const { id, factCheck } = action.payload;
      return {
        ...state,
        dialogues: {
          ...state.dialogues,
          [id]: {
            ...state.dialogues[id],
            factCheck,
          },
        },
      };
    }
    default:
      return state;
  }
}
