import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';
import Checkbox from '@mui/material/Checkbox';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import { HeaderContext } from '@tanstack/react-table';

export default function Select({
  table,
}: HeaderContext<FolderType | SourceType, unknown>) {
  return (
    <Checkbox
      color="primary"
      icon={<StyledCheckboxIcon />}
      checkedIcon={<StyledCheckbox />}
      indeterminateIcon={<StyledIntermittentCheckbox />}
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  );
}
