import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import useTimer from '@hooks/useTimer';
import { FactCheckType, SentimentLabels } from '@shared-types/sidekick';
import { DataPacket_Kind, Participant, Room, RoomEvent } from 'livekit-client';
import { useRef, useState, useSyncExternalStore } from 'react';

type SpeechToTextFinalDataType = {
  type: 'stt_sentence';
  text: string;
  index: number;
};

type SemanticSearchDataType = {
  type: 'semantic_search';
  index: number;
  result: SentimentLabels;
};

type SpeechToTextTempDataType = {
  type: 'stt_temp';
  text: string;
  index: number;
};

type FactCheckSearchDataType = {
  index: number;
  avatar: string;
  type: 'fact_check';
  result: FactCheckType;
};

type ParticipantsType =
  | SpeechToTextFinalDataType['type']
  | SemanticSearchDataType['type']
  | SpeechToTextTempDataType['type']
  | FactCheckSearchDataType['type'];

export default function useLiveKitTranscriber(room: Room) {
  const [interimTranscript, setInterimTranscript] = useState<string | null>(
    null,
  );
  const { current: textDecoderRef } = useRef(new TextDecoder());

  const { minutes, seconds } = useTimer();

  const {
    handleSetDialogue,
    handleUpdateFactCheckById,
    handleUpdateSentimentsById,
  } = useSideKick();

  function subscriber(onStoreChange: VoidFunction) {
    function handleDataReceived(
      payload: Uint8Array,
      _participant?: Participant,
      _kind?: DataPacket_Kind,
      topic?: string,
    ) {
      const strData = textDecoderRef.decode(payload);
      const parsedData = JSON.parse(strData) as unknown;

      switch (topic as ParticipantsType) {
        case 'stt_temp': {
          const typedParsedData = parsedData as SpeechToTextTempDataType;
          const text = typedParsedData.text;
          setInterimTranscript(text);
          onStoreChange();
          break;
        }
        case 'stt_sentence': {
          const typedParsedData = parsedData as SpeechToTextFinalDataType;
          const text = typedParsedData.text;
          handleSetDialogue({
            content: text,
            id: typedParsedData.index.toString(),
            time: { minutes: minutes.toString(), seconds: seconds.toString() },
          });
          setInterimTranscript(null);
          onStoreChange();
          break;
        }
        case 'semantic_search': {
          const typedParsedData = parsedData as SemanticSearchDataType;
          const { result, index } = typedParsedData;
          handleUpdateSentimentsById({
            id: index.toString(),
            sentiments: result,
          });
          break;
        }
        case 'fact_check': {
          const typedParsedData = parsedData as FactCheckSearchDataType;
          const { index, result } = typedParsedData;
          handleUpdateFactCheckById({
            id: index.toString(),
            factCheck: result,
          });
          break;
        }
        default: {
          console.error('Unknown topic', topic);
        }
      }
    }

    function handleParticipantConnected(participants: Participant) {
      // eslint-disable-next-line no-restricted-syntax
      console.info('🔌🔌🔌 connected participants: ', participants.identity);
    }

    function handleParticipantDisconnected(participants: Participant) {
      // eslint-disable-next-line no-restricted-syntax
      console.info('🚫🚫🚫 disconnected participants: ', participants.identity);
    }

    room.on(RoomEvent.DataReceived, handleDataReceived);
    room.on(RoomEvent.ParticipantConnected, handleParticipantConnected);
    room.on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected);
    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived);
      room.off(RoomEvent.ParticipantConnected, handleParticipantConnected);
      room.off(
        RoomEvent.ParticipantDisconnected,
        handleParticipantDisconnected,
      );
    };
  }

  function getSnapshot() {
    return room.state === 'connected' ? interimTranscript : null;
  }

  const transcript = useSyncExternalStore(subscriber, getSnapshot);

  return {
    transcript,
  };
}
