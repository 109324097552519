import StyledMainBox from '@layouts/main/sections/toolbar/components/content/styles';
import CONFIG from '@layouts/main/sections/toolbar/config';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { convertToRem } from '@lib/fonts';

export default function Content({ children }: React.PropsWithChildren) {
  const { toolbarExpansionStatus } = useToolbar();

  const isToolbarExpanded = toolbarExpansionStatus === 'on';

  return (
    <StyledMainBox
      component="main"
      sx={{
        marginInlineStart: {
          md: isToolbarExpanded
            ? 0
            : convertToRem(
                CONFIG.TOOLBAR_MINI.DESKTOP.WIDTH +
                  CONFIG.PATH_TO_SHOW_COLLAPSING_BUTTON,
              ),
        },
        flex: isToolbarExpanded ? 1 : undefined,
      }}
    >
      {children}
    </StyledMainBox>
  );
}
