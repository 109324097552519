import List from '@components/pages/side-kick-session/sections/content/components/feed/list';
import Selector from '@components/pages/side-kick-session/sections/content/components/feed/selector';
import ContentLayout from '@components/pages/side-kick-session/sections/content/content-layout';

export default function Feed() {
  return (
    <ContentLayout headerSlot={<Selector />}>
      <List />
    </ContentLayout>
  );
}
