import useMutateLiveToken from '@components/pages/side-kick/hooks/useMutateLiveToken';
import { useTranslation } from '@desygner/ui-common-translation';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import usePermission from '@hooks/useNavigatorPermissions';
import useTimer from '@hooks/useTimer';
import paths from '@router/paths';
import { isRecordingUpdated } from '@state/slices/transcription';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function useStartRecordingSession() {
  const [isSideKickSessionStarting, setIsSideKickSessionStaring] =
    useState(false);

  const { t } = useTranslation();

  const smartTags = useAppSelector((state) => state.transcription.smartTags);

  const navigateTo = useNavigate();

  const dispatch = useAppDispatch();

  const { factChecker } = useAppSelector(
    (state) => state.transcription.selectedAssistants,
  );

  const timer = useTimer();

  const { mutateLiveTokenAsync, isMutateLiveTokenPending } =
    useMutateLiveToken();

  //TODO: useGeolocation should be handled like this with useNavigatorPermissions that I wrote
  const {
    permission: { state: permissionState, isLoading: isPermissionLoading },
    requestMicrophone,
  } = usePermission('microphone');

  useEffect(() => {
    if (permissionState === 'granted' && isSideKickSessionStarting) {
      (async function () {
        try {
          const { data } = await mutateLiveTokenAsync({
            factCheck: factChecker,
            semantic: {
              training_sets: Array.from(
                new Set(Object.values(smartTags).flat()),
              ), //TODO: waiting for design and decision on how to handle training sets
            },
            restart: false,
            enableRecording: false,
          });

          navigateTo(`${paths.sideKickSession.pathname}?t=${data.threadId}`, {
            state: {
              token: data.token,
            },
          });
        } catch (error) {
          console.error(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState, isSideKickSessionStarting]);

  const handleStartRecoding = useCallback(() => {
    (async function () {
      if (permissionState === 'denied') {
        toast.info(
          t('page.sideKickSession.mic.denied', {
            defaultValue: 'Your mic access denied please allow it first',
          }),
          { autoClose: false },
        );
        return;
      }
      requestMicrophone();
      dispatch(isRecordingUpdated(true));
      timer.startTimer();
      setIsSideKickSessionStaring(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState, requestMicrophone]);

  return {
    handleStartRecoding,
    isMutateLiveTokenPending,
    isPermissionLoading,
  };
}
