import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export const StyledTypographyWithDivider = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(5),
  borderBlockEnd: '1px solid',
  borderColor: theme.palette.divider,
  alignSelf: 'flex-start',
  justifySelf: 'flex-start',
  width: '100%',
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  height: '100%',
}));

export const StyledGridItemContent = styled(Grid)(({ theme }) => ({
  overflowY: 'auto',
  height: 'inherit',
}));
