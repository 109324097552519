import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';
import CONFIG from '@components/pages/side-kick/config';
import { useTranslation } from '@desygner/ui-common-translation';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { AssistantsKindType } from '@shared-types/transcription';
import { selectedAssistantsUpdated } from '@state/slices/transcription';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  anchor?: 'left' | 'right';
};

const SIZE = 30;

const ARRAY_OF_ASSISTANTS = Object.entries(CONFIG.KNOWZ_ASSISTANTS) as [
  AssistantsKindType,
  {
    url: string;
    title: string;
    description: string;
  },
][];

export default function Drawer({ onClose, open, anchor = 'right' }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedAssistants = useAppSelector(
    (state) => state.transcription.selectedAssistants,
  );

  return (
    <MuiDrawer open={open} onClose={onClose} anchor={anchor}>
      <Box
        sx={{
          width: {
            md: 400,
          },
        }}
      >
        <Stack direction="row" sx={{ p: 6, alignItems: 'center' }}>
          <Stack sx={{ flex: 1 }}>
            <Typography variant="subtitle1">
              {t(
                'page.sideKick.grids.mode.cards.knowzAssistant.drawer.customize.title',
                {
                  defaultValue: 'Customize KnowZ Assistant',
                },
              )}
            </Typography>
            <Typography variant="body2" color="text.disabled">
              {t(
                'page.sideKick.grids.mode.cards.knowzAssistant.drawer.customize.subtitle',
                {
                  defaultValue:
                    'Select how you want KnowZ to interact during live recordings.',
                },
              )}
            </Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <ArrowForwardOutlinedIcon />
          </IconButton>
        </Stack>
        <Stack disablePadding component={List} gap={4} sx={{ px: 6 }}>
          {ARRAY_OF_ASSISTANTS.map(([key, value]) => (
            <ListItem
              disablePadding
              disableGutters
              key={key}
              sx={{
                alignItems: 'flex-start',
                backgroundColor: 'common.black',
                border: '1px solid',
                borderColor: selectedAssistants[key]
                  ? 'primary.main'
                  : 'transparent',
                p: 4,
                borderRadius: 2,
              }}
            >
              <Stack
                component="label"
                direction="row"
                sx={{ cursor: 'pointer', alignItems: 'flex-start' }}
              >
                <Avatar
                  src={value.url}
                  sx={{ width: SIZE, height: SIZE, marginInlineEnd: 3 }}
                />
                <ListItemText
                  sx={{ m: 0 }}
                  primary={value.title}
                  secondary={value.description}
                  secondaryTypographyProps={{
                    sx: { color: 'text.disabled' },
                  }}
                />
                <Checkbox
                  sx={{ p: 0 }}
                  defaultChecked
                  onChange={(_event, checked) => {
                    dispatch(
                      selectedAssistantsUpdated({
                        factChecker: checked,
                      }),
                    );
                  }}
                  icon={<StyledCheckboxIcon />}
                  checkedIcon={<StyledCheckbox />}
                  indeterminateIcon={<StyledIntermittentCheckbox />}
                />
              </Stack>
            </ListItem>
          ))}
        </Stack>
      </Box>
    </MuiDrawer>
  );
}
