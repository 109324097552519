import type { ThemeType } from '@shared-types/theme';

export default function Paper(theme: ThemeType) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none!important',
          backgroundColor: theme.palette.background.card.light,
          padding: theme.spacing(3),
          '&.MuiPaper-rounded': {
            borderRadius: theme.shape.borderRadius * 4,
          },
        },
      },
    },
  };
}
