import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.spacing(4),
}));

export default StyledStack;
