import Typography from '@mui/material/Typography';
import { useStopwatch } from 'react-timer-hook';

export default function Timer() {
  //TODO: we should use this hook for 10 minutes timer in code verification step in secure account
  const { minutes, seconds, hours } = useStopwatch({
    autoStart: true,
  });

  return (
    <Typography variant="body1" sx={{ textAlign: 'center' }}>
      {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </Typography>
  );
}
