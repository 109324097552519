import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import { useState } from 'react';

type Props = {
  SummarySlot: React.ReactNode;
  DetailsSlot: React.ReactNode;
};

export default function UncontrolledAccordion({
  DetailsSlot,
  SummarySlot,
}: Props) {
  const [isOpen, setIsOpen] = useState(true);

  function toggleAccordion() {
    setIsOpen(!isOpen);
  }

  return (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true } }}
      sx={{
        backgroundColor: 'background.card.main',
      }}
      expanded={isOpen}
      onChange={toggleAccordion}
    >
      <AccordionSummary
        sx={{ alignItems: 'center', gap: 3 }}
        expandIcon={<ExpandMoreIcon />}
      >
        {SummarySlot}
      </AccordionSummary>
      <Divider sx={{ my: 3 }} />
      <AccordionDetails sx={{ p: 0 }}>{DetailsSlot}</AccordionDetails>
    </Accordion>
  );
}
