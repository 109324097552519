import Recorder from '@components/pages/side-kick-session/recorder/desktop';
import Feed from '@components/pages/side-kick-session/sections/content/components/feed';
import Transcript from '@components/pages/side-kick-session/sections/content/components/transcript';
import {
  StyledGridContainer,
  StyledGridItemContent,
} from '@components/pages/side-kick-session/sections/content/styles';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

export default function Content() {
  const theme = useTheme();

  return (
    <>
      <StyledGridContainer container>
        <StyledGridItemContent item md={3}>
          <Stack
            direction="row"
            sx={{
              height: 'inherit',
            }}
          >
            <Transcript />
            <Divider
              orientation="vertical"
              flexItem
              color={
                theme.palette.mode === 'light'
                  ? theme.palette.grey[50]
                  : theme.palette.grey[900]
              }
            />
          </Stack>
        </StyledGridItemContent>
        <StyledGridItemContent item md={9}>
          <Stack
            direction="row"
            sx={{
              height: 'inherit',
            }}
          >
            <Feed />
          </Stack>
        </StyledGridItemContent>
      </StyledGridContainer>
      <Recorder />
    </>
  );
}
