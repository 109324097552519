import Switch from '@components/switch';
import TuneIcon from '@mui/icons-material/Tune';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { StyledCard } from '@components/pages/side-kick/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import { useState } from 'react';
import Drawer from '@components/pages/side-kick/sections/cards/knowz-assistant-card/drawer';

const IMAGE_SIZE = 40;

export default function KnowzAssistantCard() {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function handleDrawerToggle() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  return (
    <StyledCard>
      <Stack gap={4}>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
          gap={4}
        >
          <Stack
            direction="row"
            sx={{ alignItems: 'center', flexWrap: 'wrap' }}
            gap={3}
          >
            <img
              src="https://assets.knowz.com/assets/svg/ai-orb.svg"
              alt="knowz live"
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
              loading="lazy"
            />
            <Typography variant="subtitle1" color="text.secondary">
              {t('page.sideKick.grids.mode.cards.knowzAssistant.title', {
                defaultValue: 'Include KnowZ Assistant',
              })}
            </Typography>
            <Chip label="Recommended" variant="outlined" size="small" />
          </Stack>
          <Stack direction="row" gap={5}>
            <Button
              disableRipple
              disableTouchRipple
              onClick={handleDrawerToggle}
            >
              <Stack direction="row" gap={1}>
                <TuneIcon color="disabled" />
                <Typography variant="subtitle2" color="text.disabled">
                  {t(
                    'page.sideKick.grids.mode.cards.knowzAssistant.CustomizeBtn',
                    {
                      defaultValue: 'Customize',
                    },
                  )}
                </Typography>
              </Stack>
            </Button>
            <Switch defaultChecked={true} />
            <Drawer onClose={handleDrawerToggle} open={isDrawerOpen} />
          </Stack>
        </Stack>
        <Typography variant="body1" color="text.disabled">
          {t('page.sideKick.grids.mode.cards.knowzAssistant.description', {
            defaultValue:
              'Add KnowZ AI Assistant to any live meeting for expert advice, real-time suggestions, note-taking, and guidance. With direct access to your workspace knowledge base, KnowZ makes organizing and updating data easy.',
          })}
        </Typography>
      </Stack>
    </StyledCard>
  );
}
