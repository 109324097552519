import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import LiveKitAudioTranscriber from '@components/pages/side-kick-session/sections/content/components/transcript/LiveKitAudioTranscriber';
import StyledList from '@components/pages/side-kick-session/sections/content/components/transcript/styles';
import ContentLayout from '@components/pages/side-kick-session/sections/content/content-layout';
import ListItem from '@components/pages/side-kick-session/sections/content/listitem';
import { useTranslation } from '@desygner/ui-common-translation';
import { useRemoteParticipants } from '@livekit/components-react';
import Typography from '@mui/material/Typography';

export default function Transcript() {
  const { t } = useTranslation();

  const { dialogues } = useSideKick();

  const participants = useRemoteParticipants();

  const doWeHaveParticipants = participants.length > 0;

  return (
    <ContentLayout
      headerSlot={
        <Typography>
          {t('page.sideKickSession.transcript', {
            defaultValue: 'Transcript',
          })}
        </Typography>
      }
    >
      <StyledList disablePadding component="ol">
        {doWeHaveParticipants ? (
          <>
            {Object.entries(dialogues).map(([id, dialogue]) => (
              <ListItem key={id} {...dialogue} />
            ))}
            <LiveKitAudioTranscriber />
          </>
        ) : (
          <Typography>
            {t('page.sideKickSession.waitingForParticipants', {
              defaultValue: 'Connecting to the room...',
            })}
          </Typography>
        )}
      </StyledList>
    </ContentLayout>
  );
}
