import StyledButton from '@components/pages/side-kick-session/recorder/desktop/styles';
import Timer from '@components/pages/side-kick-session/recorder/LiveTimer';
import { useTranslation } from '@desygner/ui-common-translation';
import useTranscriptionService from '@hooks/useTranscriptionService';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';

export default function Recorder() {
  const { stopTranscript } = useTranscriptionService();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const navigateTo = useNavigate();

  async function handleStopRecording() {
    confirm({
      title: t('page.sideKickSession.confirmStopRecording.title', {
        defaultValue: 'Stop Recording Session',
      }),
      description: t('page.sideKickSession.confirmStopRecording.content', {
        defaultValue: 'Are you sure you want to stop recording?',
      }),
      confirmationText: t('page.sideKickSession.confirmStopRecording.yes', {
        defaultValue: 'Yes, I want to stop recording',
      }),
      cancellationText: t('page.sideKickSession.confirmStopRecording.no', {
        defaultValue: 'No, I want to continue',
      }),
    })
      .then(() => {
        stopTranscript();
        navigateTo(paths.sideKick.pathname);
      })
      .catch(() => null);
  }

  return (
    <Card>
      <Stack direction="row" gap={2.5} sx={{ alignItems: 'center' }}>
        <StyledButton
          color="error"
          onClick={handleStopRecording}
          disableRipple
          disableTouchRipple
        >
          <AdjustOutlinedIcon fontSize="large" />
          {/* {t('page.sideKickSession.stopRecording', {
            defaultValue: 'Stop Recording',
            })} */}
        </StyledButton>
        <Typography variant="body1" sx={{ md: 'block', xs: 'none' }}>
          Recording
        </Typography>
        <Timer />
      </Stack>
    </Card>
  );
}
