import CONFIG from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import { styled, keyframes } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

export const StyledAnimatedBox = styled(Box)(({ theme }) => {
  const animatedBackground = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  `;

  return {
    // animation: `${animatedBackground} 5s linear infinite alternate`,
    backgroundPosition: '100% 0%',
    backgroundSize: '120% 100%',
    backgroundRepeat: 'no-repeat',
    opacity: theme.palette.mode === 'dark' ? 1 : 0.5,
    backgroundImage: `linear-gradient(90deg,
      #BDFFDF 30%,
      ${theme.palette.neutral.paper} 35%,
      #9CEDFF 30%,
      #9CEDFF 75%,
      ${theme.palette.neutral.paper} 83%,
      #FFBDE8 70%
    ), linear-gradient(to left, #000000a8, #000000a8)`,
    backgroundBlendMode: theme.palette.mode === 'dark' && 'overlay',
    position: 'absolute',
    top: `calc(0% - ${convertToRem(CONFIG.HEADER.HEIGHT)})`,
    height: theme.spacing(170),
    width: '70%',
    left: '50%',
    transform: 'translateX(-50%)',
    borderBottomLeftRadius: '100%',
    borderBottomRightRadius: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    filter: 'blur(100px)',
    zIndex: -1,
    willChange: 'background-position',
  };
});

export const StyledViewportHeightStack = styled(Stack)(() => ({
  justifyContent: 'center',
  height: '100svh',
}));

export const StyledCenteredContent = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledContainerForFixedHeader = styled(Container)(({ theme }) => ({
  marginTop: convertToRem(CONFIG.HEADER.HEIGHT + CONFIG.HEADER.PADDING_Y),
  paddingBottom: theme.spacing(35), //? extra padding to prevent content from being hidden behind the footer
}));

export const StyledCheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  width: theme.spacing(4),
  height: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  outline: `1px solid ${theme.palette.divider}`,
}));

export const StyledCheckbox = styled(StyledCheckboxIcon)(({ theme }) => {
  const CHECKBOX_SIZE = theme.spacing(4);
  return {
    width: CHECKBOX_SIZE,
    height: CHECKBOX_SIZE,
    '&::before': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      display: 'block',
      width: CHECKBOX_SIZE,
      height: CHECKBOX_SIZE,
      color: theme.palette.primary.contrastText,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  };
});

export const StyledIntermittentCheckbox = styled(StyledCheckboxIcon)(
  ({ theme }) => {
    const CHECKBOX_SIZE = theme.spacing(4);
    return {
      width: CHECKBOX_SIZE,
      height: CHECKBOX_SIZE,
      '&::before': {
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.primary.main,
        display: 'block',
        width: CHECKBOX_SIZE,
        height: CHECKBOX_SIZE,
        color: theme.palette.primary.contrastText,
        content: '""',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M19 13H5v-2h14z'" +
          " fill='%23fff'/%3E%3C/svg%3E\")",
      },
    };
  },
);
