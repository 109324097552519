import { FactCheckType } from '@shared-types/sidekick';

const FactCheck: {
  [key in FactCheckType['result'][number]['outcome']]:
    | 'error'
    | 'success'
    | 'default'
    | 'info';
} = {
  false: 'error',
  true: 'success',
  not_applicable: 'default',
  mixed: 'info',
};

export default function calculateFactCheckColor(
  outcome: FactCheckType['result'][number]['outcome'],
) {
  return FactCheck[outcome];
}
