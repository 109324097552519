import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';
import Checkbox from '@mui/material/Checkbox';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import { CellContext } from '@tanstack/react-table';

export default function Select({
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  return (
    <Checkbox
      icon={<StyledCheckboxIcon />}
      checkedIcon={<StyledCheckbox />}
      indeterminateIcon={<StyledIntermittentCheckbox />}
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
    />
  );
}
