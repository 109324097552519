import { AssistantsType } from '@components/pages/side-kick/types';

const ASSISTANTS: AssistantsType[] = [
  {
    fieldName: 'knowzScribe',
    label: 'Knowz Scribe',
    image: 'https://assets.knowz.com/assets/svg/ai-orb.png',
    description:
      'Offers general suggestions and automation of knowledge base data management.',
  },
  {
    fieldName: 'noteTaker',
    label: 'Note Taker',
    image: 'https://assets.knowz.com/assets/svg/note-taker.png',
    description:
      'Provides feedback and suggestions for improvement based on your recording.',
  },
  {
    fieldName: 'factChecker',
    label: 'Fact Checker',
    image: 'https://assets.knowz.com/assets/svg/fact-checker.png',
    description:
      'Verifies spoken claims using internet sources and uploaded files.',
  },
  {
    fieldName: 'mentor',
    label: 'Mentor',
    image: 'https://assets.knowz.com/assets/svg/mentor.png',
    description: 'Captures key points and insights from lectures or speeches.',
  },
];

const CONFIG = {
  ASSISTANTS,
} as const;

export default CONFIG;
