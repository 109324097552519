import { SentimentResponseType } from '@shared-types/transcription';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetSentimentLabelsFromCDN() {
  return useQuery({
    queryKey: ['sentimentLabels'],
    queryFn: async () => {
      try {
        const res = await axios.get<SentimentResponseType>(
          'https://assets.knowz.com/knowz-live/knowz-live-classifications-sentiments-colors.json',
        );
        return res.data;
      } catch (error) {
        console.error(
          'Error while fetching sentiments color from CDN: ',
          error,
        );
      }
    },
    staleTime: Infinity,
  });
}
