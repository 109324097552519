import {
  AutocompleteValueOnChangeType,
  SmartTagsKindType,
} from '@components/pages/side-kick/types';

//TODO: we need to refactor this once we have the API
const SIDE_KICK_MODES: AutocompleteValueOnChangeType = [
  {
    type: 'training-sets',
    image: 'https://assets.knowz.com/assets/images/Action-Points-Logger.png',
    title: 'Action Points Logger',
    description:
      'The Action Points Logger is trained to track and record specific actions or decisions made during dialogues or interactions with users.',
    cats: [
      'meeting-elements',
      'action-points',
      'question-detection',
      'decision-points',
      'meeting-notes',
      'follow-up-items',
      'urgency-signals',
      'sentiment-detection',
    ],
  },
  {
    type: 'training-sets',
    image: 'https://assets.knowz.com/assets/images/Conversation-Moderation.png',
    title: 'Conversation Moderation',
    description:
      'Conversation Moderation involves the systematic management and oversight of dialogues or interactions, typically in digital environments, to ensure they adhere to specific guidelines, rules, or standard',
    cats: [
      'moderation-category',
      'sentiment-detection',
      'content-sensitivity',
      'bias-detection',
      'nsfw-detection',
      'fallacy-detection',
    ],
  },
  {
    type: 'training-sets',
    image: 'https://assets.knowz.com/assets/images/Minutes-Tracker.png',
    title: 'Minutes Tracker',
    description:
      'The Minutes Tracker is trained to systematically record and document the proceedings and decisions made during meetings or sessions',
    cats: [
      'meeting-elements',
      'place-detection',
      'time-detection',
      'rsvp-detection',
      'sentiment-detection',
    ],
  },
  {
    type: 'training-sets',
    image:
      'https://assets.knowz.com/assets/images/Sales-Conversation-Analyser.png',
    title: 'Sales Conversation Analyser',
    description:
      'The Sales Conversation Analyzer is a specialized set designed to analyze and evaluate sales conversations to improve effectiveness and outcomes',
    cats: [
      'customer-sentiment',
      'question-detection',
      'product-interest-level',
      'objection-handling',
      'urgency-signals',
      'compliance-adherence',
      'upsell-opportunities',
      'key-phrase-detection',
    ],
  },
];

const FACT_CHECK: AutocompleteValueOnChangeType = [
  {
    type: 'fact-check',
    image: 'https://assets.knowz.com/assets/images/fact-check.png',
    title: 'Fact Checker',
    description:
      'The Fact Checker is designed to verify the accuracy of statements or claims made during dialogues or interactions',
  },
];

const SMART_TAGS: {
  [key in SmartTagsKindType]: {
    title: string;
    items: Array<{ title: string; subtitle: string; id: number; uuid: string }>;
  };
} = {
  conversationModeration: {
    title: 'Conversation Moderation',
    items: [
      {
        title: 'Content Sensitivity',
        subtitle: 'Tag and rate content sensitive topics',
        id: 40,
        uuid: 'b036b99a-ff20-4ba4-b9e8-4157a4f6dca2',
      },
      {
        title: 'Bias Detection',
        subtitle: 'Tag potentially harmful biased speech',
        id: 38,
        uuid: '31693d8a-b334-4626-80aa-aeadaab39dab',
      },
      {
        title: 'Fallacy Detection',
        subtitle:
          'Tag Ad hominem attacks, strawman arguments and other common fallacies',
        id: 43,
        uuid: '0b8a4d6c-8234-4e8a-8c66-9bf3878929bb',
      },
      {
        title: 'NSFW Detection',
        subtitle: 'Not safe for work content detection',
        id: 50,
        uuid: 'e8a3de32-3b9a-45a1-9fed-f447e565ab53',
      },
      {
        title: 'Customer Sentiment',
        subtitle:
          'Tag and rate customer sentiment and satisfaction related content',
        id: 56,
        uuid: '9ec75466-57f4-47d5-8623-4d089b5b16ce',
      },
      {
        title: 'Moderation Category',
        subtitle: 'Tag and rate content moderation categories',
        id: 49,
        uuid: '28d731da-0aee-4c09-812b-ee97197ad4e1',
      },
    ],
  },
  actionPointsLogger: {
    title: 'Action Points Logger',
    items: [
      {
        title: 'Action Points',
        subtitle: 'Tag and rate content related to actions and decisions',
        id: 37,
        uuid: '89970ddb-ff22-46d6-b5a8-a625ccec9f86',
      },
      {
        title: 'Decision Points',
        subtitle: 'Tag and rate content related to decisions',
        id: 42,
        uuid: 'fe935b41-d47e-4745-a5bb-bb6638cf6839',
      },
      {
        title: 'Follow up items',
        subtitle: 'Tag and rate content related to follow up items and tasks',
        id: 44,
        uuid: 'f2b6b9e3-1b5d-4d1c-9f6c-3c1b7b7b8a1b',
      },
      {
        title: 'Meetings Notes',
        subtitle: 'Tag and rate content related to meeting notes',
        id: 48,
        uuid: '656da098-dfa8-473c-8808-777d19b98b37',
      },
      {
        title: 'Question Detection',
        subtitle: 'Tag and rate questions, queries and inquiries',
        id: 54,
        uuid: 'ff5e2f03-8f78-422b-8f7f-1507fdb31ee2',
      },
      {
        title: 'Sentiment Detection',
        subtitle: 'Tag and rate content related to sentiment',
        id: 56,
        uuid: '0e14728e-3704-4efc-a34a-beabdb6dbd03',
      },
      {
        title: 'Urgency Signals',
        subtitle: 'Tag and rate content related to urgency signals',
        id: 59,
        uuid: '0143f860-58ec-4451-a22a-53768f9f2ed4',
      },
      {
        title: 'Meeting Elements',
        subtitle: 'Tag and rate content related to meeting elements',
        id: 47,
        uuid: 'c8af8c5e-01bb-4c69-8f92-de15a3b73699',
      },
    ],
  },
  minutesTracker: {
    title: 'Minutes Tracker',
    items: [
      {
        title: 'Meeting Elements',
        subtitle: 'Tag and rate content related to meeting elements',
        id: 47,
        uuid: '31c11f53-804e-4dc2-8368-610036aa415e',
      },
      {
        title: 'Place Detection',
        subtitle: 'Tag content mentioning places both virtual and real',
        id: 52,
        uuid: 'd4ed3dd1-bc13-475f-8c2c-84d0951cacdb',
      },
      {
        title: 'RSVP Detection',
        subtitle: 'Tag content related to attendance commitments',
        id: 55,
        uuid: '98c73254-4816-402b-b478-7bbba5d60dbf',
      },
      {
        title: 'Sentiment Detection',
        subtitle: 'Tag and rate content related to sentiment detection',
        id: 56,
        uuid: 'bba98f32-37f9-4907-9794-2709e384f32b',
      },
      {
        title: 'Time Detection',
        subtitle: 'Tag content mentioning days and times',
        id: 57,
        uuid: '5ee1269e-c71c-40c4-9fe7-269e1efa3d78',
      },
    ],
  },
  salesConversationAnalyser: {
    title: 'Sales Conversation Analyser',
    items: [
      {
        title: 'Compliance Adherence',
        subtitle: 'Tag compliance related topics',
        id: 39,
        uuid: 'f4283c86-7cf6-4f96-af96-1f266b7bb9b5',
      },
      {
        title: 'Customer Sentiment',
        subtitle:
          'Tag and rate customer sentiment and satisfaction related content',
        id: 41,
        uuid: '3c757574-53dd-4abd-a05e-1d15a571cef4',
      },
      {
        title: 'Key Phrase Detection',
        subtitle:
          'Tag pre-defined key phrases relating to potential sales and budgets',
        id: 46,
        uuid: '09658547-802f-424c-99df-90e4782e58a4',
      },
      {
        title: 'Objection Handling',
        subtitle: 'Tag content related to handling customer objections',
        id: 51,
        uuid: 'e1930f94-f860-4f71-aed0-7ac1fe8ffbea',
      },
      {
        title: 'Product Interest Level',
        subtitle: 'Tag and rate product interest levels',
        id: 53,
        uuid: '530fcd0c-a46e-4168-8b8a-8b69b157178b',
      },
      {
        title: 'Question Detection',
        subtitle: 'Tag and rate questions, queries and inquiries',
        id: 54,
        uuid: '5c86a003-90c1-4b04-843f-cb473947f62a',
      },
      {
        title: 'Urgency Signals',
        subtitle:
          'Tag and rate potentially urgent customer support related issues',
        id: 59,
        uuid: '9db2d76d-ac9a-4d8d-bb25-2b9d42c89045',
      },
      {
        title: 'Upsell Opportunities',
        subtitle: 'Tag potentially upsell opportunities',
        id: 58,
        uuid: 'e65f97ae-0ff3-41da-9998-ac49985eea8e',
      },
    ],
  },
};

const KNOWZ_ASSISTANTS = {
  // scribe: {
  //   url: 'https://s3.us-east-1.amazonaws.com/assets.knowz.com/assets/svg/ai-orb.svg',
  //   title: 'Knowz Scribe',
  //   description:
  //     'Answers questions integrating automation of knowledge base management',
  // },
  // moderator: {
  //   title: 'Knowz Moderator',
  //   description:
  //     'A comprehensive chat moderator who can flag any potentially problematic speech',
  // },
  // noteTaker: {
  //   url: 'https://s3.us-east-1.amazonaws.com/assets.knowz.com/assets/svg/note-taker.svg',
  //   title: 'Knowz Note Taker',
  //   description: 'Capture key points and insights during recordings',
  // },
  // salesAssistant: {
  //   title: 'Knowz Sales Assistant',
  //   description:
  //     'Detect key phrases, upsell opportunities and product interest levels',
  // },
  // customerSupport: {
  //   title: 'Knowz Customer Support',
  //   description:
  //     'Detect Urgency signals, objection handling content and rate customer sentiment',
  // },
  factChecker: {
    url: 'https://s3.us-east-1.amazonaws.com/assets.knowz.com/assets/svg/fack-checker.svg',
    title: 'Knowz Fact Checker',
    description:
      'Verify or correct claims using internet sources & uploaded files.',
  },
};

export default {
  SIDE_KICK_MODES,
  FACT_CHECK,
  SMART_TAGS,
  KNOWZ_ASSISTANTS,
};
