import { styled } from '@mui/material';
import List from '@mui/material/List';

const StyledList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
})) as typeof List;

export default StyledList;
