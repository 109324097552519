import { useFormContext, Controller } from 'react-hook-form';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

interface RHFCheckboxProps
  extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  CheckboxProps?: CheckboxProps;
  label?: string;
}

export default function RHFCheckbox({
  name,
  CheckboxProps,
  label,
  ...other
}: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      label={
        <Typography color="text.secondary" variant="body2">
          {label}
        </Typography>
      }
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox {...field} checked={field.value} {...CheckboxProps} />
          )}
        />
      }
      {...other}
    />
  );
}
